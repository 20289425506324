.cookie {
  &_info {
    overflow: hidden;

    height: 0;

    &--active {
      height: auto;
    }
  }

  &_item {
    margin-bottom: 10px;
    padding: 10px;

    background-color: #9A9EA3;
    border-radius: 10px;

    &__el {
      display: flex;
      align-items: center;

      margin-bottom: 10px;
    }

    &__name {
      width: 100px;
      margin-right: 40px;

      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      line-height: 16px;
      color: #000;
    }

    &__value {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      line-height: 16px;
    }
  }
}
