@import 'assets/styles/index';

.footer {
  @include for-size(tablet-phone) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  @include for-size(tablet-laptop) {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  background: $color-background-dark;

  &__inner {
    @include for-size(tablet-phone) {
      flex-direction: column;

      margin-top: 24px;
      margin-bottom: 24px;
    }
    @include for-size(tablet-laptop) {
      flex-direction: column;

      margin-top: 24px;
      margin-bottom: 24px;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 28px;
    margin-bottom: 28px;
    padding-right: 0;
    padding-left: 0;

    &_bottom {
      display: flex;
      justify-content: center;
    }
  }

  &__divider {
    @include for-size(tablet-phone) {
      display: block;

      width: 100%;
      margin-bottom: 24px;

      border-top: 1px solid #3a3a3c;
    }

    display: none;
  }

  &__logo {
    height: 45px;
  }
}
