/* stylelint-disable selector-class-pattern */
@import 'assets/styles/index';

.error-page {
  min-height: 100vh;
  padding: 40px 0;

  background-color: $color-background-grey;

  &__content {
    @extend %box-shadow-secondary;
    @include for-size(phone) {
      flex-direction: column-reverse;

      padding: 30px 20px;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;

    max-width: 900px;
    margin: 0 auto;
    padding: 40px;

    background: $color-background-light;
    border-radius: 10px;
  }

  &__title {
    @include text-size(normal, normal, 24px, 29px);
    @include for-size(phone) {
      align-self: center;
    }

    margin: 0;
    margin-bottom: 20px;

    color: $color-text-dark-primary;
  }

  &__description {
    @include text-size(normal, normal, 16px, 145%);

    margin-bottom: 20px;

    color: $color-text-dark-primary;

    opacity: 0.7;
  }

  &__info {
    display: flex;
    flex-basis: 60%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__button {
    @include for-size(phone) {
      margin: 0 auto;
    }
  }

  &__icon {
    @include for-size(phone) {
      width: 60px;
      height: 60px;
      margin-bottom: 30px;
    }

    width: 100px;
    height: 100px;
  }
}
