@import 'assets/styles/index';

.customize {
  &-cookie {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;

    &__description {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      line-height: 18px;
      color: #fff;
    }

    &__content {
      overflow-x: auto;

      height: 75%;
      padding-right: 20px;
    }

    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 20px;
      padding: 0 0 20px;

      border-bottom: 1px solid #fff;

      &_title {
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: 18px;
        color: #fff;
      }

      &_button {
        cursor: pointer;

        padding: 0;

        font: inherit;
        color: inherit;

        background: none;
        border: none;
        outline: inherit;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    &__footer {
      @include for-size(tablet-phone) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: 180px;
      }

      display: flex;
      align-items: center;
      justify-content: space-between;

      height: 80px;
      padding: 20px 0 0;

      &_button {
        @include for-size(tablet-phone) {
          margin: 0 0 20px;
        }

        width: 100%;
        margin: 0 10px;

        &:last-child {
          @include for-size(tablet-phone) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
