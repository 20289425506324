@import 'assets/styles/index';

.download {
  @include for-size(small-phone) {
    flex-direction: column;
  }

  display: flex;

  padding: 0;

  &__item {
    @include for-size(small-phone) {
      margin: 10px 0;
    }

    display: flex;

    margin-right: 16px;
    margin-left: 16px;

    color: $color-text-light-primary;

    border-radius: 6px;

    &:hover {
      opacity: 0.7;
    }
  }
}
