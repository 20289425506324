// fonts
$font-golos: 'Golos', sans-serif;

// colors
$color-background-dark: #091C32;
$color-background-grey: #e5e5e5;
$color-background-light-grey: #f7f7f8;
$color-background-light: #fff;
$color-text-light-primary: #fff;
$color-text-light-secondary: #a19797;
$color-text-light-tertiary: #fdfbfb;
$color-text-dark-primary: #171717;
$color-text-dark-secondary: #0a1c33;
$color-text-blue: #2f80ed;
$color-el-primary: #2f80ed;
$color-el-cashback: #ea1e32;
$color-el-dot: #cfd6dd;
$color-el-pagination: #ebeff2;
$color-el-loader: #ea1e32;

// screens
$small-phone: 320px;
$phone: 768px;
$tablet: 1023px;
$laptop: 1200px;
$desktop: 1600px;

// z-indexes
$z-cookie-panel: 1000;
