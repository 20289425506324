@import 'assets/styles/index';

.navigation {
  @include for-size(laptop-laptop-max) {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  margin-top: 0;
  margin-bottom: 0;

  &__list {
    @include for-size(tablet-phone) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    display: flex;

    padding: 0;
  }

  &__item {
    @include for-size(tablet-phone) {
      margin: 8px 0;
    }

    margin-right: 10px;
    margin-left: 10px;

    &:first-child {
      @include for-size(tablet-phone) {
        margin-top: 24px;
      }
    }

    &:last-child {
      @include for-size(tablet-phone) {
        margin-bottom: 24px;
      }
    }
  }

  &__link {
    @include text-size(normal, normal, 16px, 19px);

    cursor: pointer;

    color: $color-text-light-primary;

    &:hover {
      opacity: 0.7;
    }
  }
}
