/* stylelint-disable selector-class-pattern */
@import './mixins';

.container-primary {
  @include for-size(phone) {
    padding: 0 16px;
  }
  @include for-size(tablet) {
    padding: 0 34px;
  }
  @include for-size(laptop) {
    padding: 0 34px;
  }

  max-width: 1110px;
  margin: 0 auto;
}

.container-secondary {
  @include for-size(tablet) {
    padding: 0 34px;
  }
  @include for-size(laptop) {
    padding: 0 30px;
  }

  max-width: 1110px;
  margin: 0 auto;
}
