@import 'assets/styles/index';

.search {
  overflow: hidden;
  display: flex;
  align-items: center;

  height: 40px;

  border-radius: 7px;

  &__input {
    width: 100%;
    height: 100%;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 56px;
    height: 100%;

    background-color: $color-el-primary;
  }
}
