@import 'assets/styles/index';

.cookie {
  &_settings {
    &_button {
      cursor: pointer;

      position: fixed;
      z-index: 998;
      bottom: 20px;
      left: 20px;

      width: 45px;
      height: 45px;

      background-color: $color-el-primary;
      border-radius: 50%;

      &:hover {
        opacity: 0.8;
      }

      svg {
        cursor: pointer;

        width: 100%;
        height: 100%;

        fill: $color-text-light-primary;
      }
    }
  }
}
