@font-face {
  font-family: Golos;
  font-weight: 400;
  font-style: normal;
  src: local('Golos'), url('./Golos/regular.ttf') format('truetype');
}
@font-face {
  font-family: Golos;
  font-weight: 500;
  font-style: normal;
  src: local('Golos'), url('./Golos/medium.ttf') format('truetype');
}
@font-face {
  font-family: Golos;
  font-weight: 600;
  font-style: normal;
  src: local('Golos'), url('./Golos/demibold.ttf') format('truetype');
}
@font-face {
  font-family: Golos;
  font-weight: 700;
  font-style: normal;
  src: local('Golos'), url('./Golos/bold.ttf') format('truetype');
}
@font-face {
  font-family: Golos;
  font-weight: 900;
  font-style: normal;
  src: local('Golos'), url('./Golos/black.ttf') format('truetype');
}
