@import 'assets/styles/index';

.header {
  @include for-size(phone) {
    height: calc(64px - 24px);

    &__language {
      margin-right: 20px;
      margin-left: auto;
    }
  }

  height: calc(68px - 24px);
  padding-top: 12px;
  padding-bottom: 12px;

  background: $color-background-dark;

  &__active {
    display: flex;
  }

  &__none {
    display: none;
  }

  &__logo {
    @include for-size(phone) {
      height: 39px;
    }

    height: 43px;
  }

  &__primary-mob {
    align-items: center;
    justify-content: space-between;
    @media (min-width: 768px) {
      display: flex;
    }
  }

  &__secondary-mob {
    align-items: center;
    justify-content: space-between;
    @media (min-width: 768px) {
      display: none;
    }
  }

  &__search {
    @include for-size(phone) {
      display: none;
    }

    width: 51.92%;
  }

  &__mob{
    &-search {
      width: 86.8%;

      &-icon {
        @include for-size(phone) {
          display: block;
        }

        cursor: pointer;

        display: none;

        &:hover{
          opacity: 0.9;
        }
      }
    }

    &-close {
      &-icon {
        @include for-size(phone) {
          display: block;
        }

        cursor: pointer;

        display: none;

        &:hover{
          opacity: 0.9;
        }
      }
    }
  }
}
