@import 'assets/styles/index';

.panel {
  position: fixed;
  z-index: $z-cookie-panel;
  bottom: 0;
  left: 0;

  width: 100%;

  color: #fff;

  background-color: #555;
}

.content {
  @include for-size(phone) {
    flex-direction: column;
  }

  display: flex;
  align-items: center;

  padding-top: 1rem;
  padding-bottom: 1rem;
}

.main {
  flex-grow: 1;
}

.link {
  font-weight: bold;
  color: #fff;

  &:hover {
    text-decoration: underline;
  }
}

.controls {
  @include for-size(phone) {
    display: flex;
    flex-direction: column;

    min-width: 300px;
    padding-top: 1rem;
    padding-left: 0;
  }

  display: flex;
  justify-content: end;

  min-width: 500px;
  padding-left: 1rem;
}

.control {
  @include for-size(phone) {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  margin-right: 0.3rem;
  margin-left: 0.3rem;
}
