@import 'assets/styles/index';


.switch {
  display: inline-block;


  &__slider {
    cursor: pointer;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;

    background-color: $color-background-grey;
    border: 0;
    border-radius: 24px;
    outline: none;

    transition: .4s;

    &::before {
      content: "";

      position: absolute;
      bottom: 4px;
      left: 4px;

      width: 16px;
      height: 16px;

      background-color: $color-background-light;
      border-radius: 50%;

      transition: .4s;
    }
  }

  span {
    position: relative;

    float: left;

    width: 40px;
    height: 24px;

    input {
      display: none;

      &:checked + .switch__slider {
        background-color: $color-el-primary;
      }

      &:checked + .switch__slider::before {
        transform: translateX(16px);
      }

      &:focus + .switch__slider {
        box-shadow: 0 0 1px $color-el-primary;
      }
    }
  }
}