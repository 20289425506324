/* stylelint-disable selector-class-pattern */
@import '../fonts/fonts';
@import './variables';
@import './containers';
@import './mixins';
@import './placeholders';

*,
html,
body {
  margin: 0;

  font-family: $font-golos;
}

html,
body,
.root {
  height: 100%;
}

.root {
  display: flex;
  flex-direction: column;
}

.main {
  flex-grow: 1;
}

h1 {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

ul {
  margin-left: 0;
  padding-left: 0;
}

a {
  color: $color-text-dark-primary;
  text-decoration: none;
}

p {
  margin: 0;
}

.button {
  cursor: pointer;

  border: none;

  &:hover {
    opacity: 0.9;
  }
}

.input {
  @include text-size(normal, normal, 14px, 17px);

  padding: 0 20px;

  color: $color-text-light-secondary;
  color: $color-text-dark-primary;

  border: none;

  &::placeholder {
    color: $color-text-light-secondary;
  }

  &:focus {
    outline: none;
  }
}

.logo {
  cursor: pointer;
}

.title {
  @include text-size(normal, normal, 42px, 50px);
  @include for-size(phone) {
    @include text-size(normal, normal, 32px, 38px);
  }

  color: $color-text-light-primary;
  text-align: center;

  &::first-letter {
    text-transform: uppercase;
  }
}

.title-container {
  @extend %main-gradient;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 120px;
}
