/* stylelint-disable selector-class-pattern */
@import 'assets/styles/index';

.language-control {
  position: relative;

  width: 85px;

  &__icon {
    width: 25px;
  }

  &__selected {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      opacity: 0.9;
    }
  }

  &__text {
    @include text-size(normal, normal, 14px, 17px);

    color: $color-text-light-primary;
  }

  &__select {
    &-list {
      position: absolute;
      z-index: 5;
      top: 30px;

      display: flex;
      flex-direction: column;

      width: 100%;
      padding: 5px;

      background-color: rgb(6, 20, 20);
      border-radius: 6px;
    }

    &-item {
      cursor: pointer;

      z-index: 4;

      display: flex;
      align-items: center;
      justify-content: space-between;

      width: calc(100% - 20px);
      margin-bottom: 10px;
      padding: 5px 10px;

      border-radius: 6px;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background-color: $color-el-primary;
      }
    }
  }
}
