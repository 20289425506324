/* stylelint-disable selector-class-pattern */
@import 'assets/styles/index';

.button {
  text-align: center;
  text-transform: uppercase;

  &-primary {
    color: $color-text-light-primary;

    background: $color-el-primary;
    box-shadow: 0 11px 25px rgba(47, 128, 237, 0.2);
  }

  &-secondary {
    color: $color-text-light-primary;

    background: transparent;
    border: 1px solid $color-background-light !important;
  }

  &-sm {
    @include text-size(normal, normal, 12px, 15px);

    padding: 10px 20px;

    border-radius: 20px;
  }

  &-md {
    @include text-size(normal, normal, 14px, 16px);

    padding: 12px 25px;

    border-radius: 24px;
  }

  &-lg {
    @include text-size(normal, normal, 16px, 19px);

    padding: 14px 30px;

    border-radius: 28px;
  }
}
