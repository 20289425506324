@import 'assets/styles/index';

.consent {
  &_item {
    padding: 10px 0;

    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 10px 0;
    }

    &__name {
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      margin-bottom: 10px;

      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      line-height: 18px;
      color: #fff;

      &:hover {
        opacity: 0.8;
      }
    }

    &__icon {
      margin-right: 10px;

      &--active {
        transform: rotate(180deg);
      }
    }

    &__description {
      margin-bottom: 10px;

      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      line-height: 18px;
      color: #fff;
    }

    &__accordion {
      overflow: hidden;

      height: auto;
      padding: 10px;
    }

    &__label {
      color: darkseagreen;
    }
  }
}
