@import 'assets/styles/index';

.socials {
  display: flex;

  padding: 0;

  &__item {
    @include for-size(phone) {
      margin-right: 16px;
      margin-left: 16px;
    }

    margin-right: 5px;
    margin-left: 5px;

    &:hover {
      opacity: 0.7;
    }
  }
}
