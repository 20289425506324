@import 'assets/styles/index';

.loading {
  position: relative;

  display: flex;

  width: 100px;
  height: 100px;
  margin: auto;
  margin-top: 15vh;
}

.loading div {
  position: absolute;
  top: 50%;
  left: 50%;

  opacity: 1;
  background: $color-el-loader;
  border-radius: 50%;

  animation: loading 1.3s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loading div:nth-child(2) {
  animation-delay: -0.7s;
}
@keyframes loading {
  0% {
    top: 48px;
    left: 48px;

    width: 0;
    height: 0;

    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;

    width: 100px;
    height: 100px;

    opacity: 0;
  }
}
